import React, { useState } from 'react';
import Slider from 'react-slick';
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_CONTENTS, getArticlesCustomQueryVariables } from 'Queries'
import config from 'Config'
import { path } from 'Utils';

const ItemManager = props => {
  let nItems = [];
  const variables = getArticlesCustomQueryVariables(
    '',
    5,
    'article',
    true,
    props.data.data.theme,
    nItems,
    'yes'
  )
  const { loading, error, data } = useQuery(GET_CONTENTS, { variables });

  const LoadURL = (type, uuid, slug) => {
    return path(type, { uuid, slug });
  }

  const LoadV1 = () => {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            centerPadding: '20px',
            slidesToShow: 2.5
          }
        },
        {
          breakpoint: 580,
          settings: {
            centerMode: true,
            centerPadding: '10px',
            slidesToShow: 1.15
          }
        }
      ]
    };

    return (
      <div className={props.className}>
      <div className="content-slider-section">
        <div className={"section-container-1140"}>
          <div className="section-heading-vertical">{props.data.data.theme}</div>
          <Slider {...settings} className="section-slider-row slick-dotted">
            {data.contents.contents.map((k) => (
              <div className="slider-card" key={k.uuid}>
                <div className="slider-card-image-block">
                  <div className="content-tag tag-blue">{k.theme}</div>
                  <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">
                    <img src={k.image.main} alt="" className="global-image" />
                  </Link>
                </div>
                <div className="slider-card-title">{k.title.short}</div>
                <div className="slider-card-description">{k.title.long}</div>
                <div className="slider-card-link-holder">
                  <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">Read more</Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      </div>
    )
  }

  const LoadV2 = () => {
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 2.5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            centerPadding: '20px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 580,
          settings: {
            centerMode: true,
            centerPadding: '10px',
            slidesToShow: 1
          }
        }
      ]
    };

    return (
      <div className={props.className}>
      <div className="content-slider-section">
        <div className={"section-container"}>
          <div className="content-slider-header">
            <div className='slider-section-heading'>{props.data.data.title}</div>
          </div>
          <Slider {...settings} className="section-slider-row slick-dotted">
            {data.contents.contents.map((k) => (
              <div className="slider-card" key={k.uuid}>
                <div className="slider-card-image-block">
                  <div className="content-tag">{k.theme}</div>
                  <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">
                    <img src={k.image.main} alt="" className="global-image" />
                  </Link>
                </div>
                <div className="slider-card-title">{k.title.short}</div>
                <div className="slider-card-description">{k.title.long}</div>
                <div className="slider-card-link-holder">
                  <Link to={LoadURL(k.type, k.uuid, k.slug)} className="primary-link">Read more</Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      </div>
    )
  }

  if(!data) return null;

  if(config.name === 'us') {
    return <LoadV2 />
  }

  return config.name === 'us' ? <LoadV2 /> : <LoadV1 />
}

export default ItemManager
